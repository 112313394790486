import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

import ErrorMessage from '../errorMessage/ErrorMessage';


const Page404 = () => {
    return (
        <div>
            <Helmet>
                <meta
                    name="description"
                    content='Page not found'
                />        
                <title>404 page</title>
            </Helmet>
            <ErrorMessage />
            <p style={{'textAlign': 'center', 'fontWeight': 'bold', 'fontSize': '24px'}}>Page doesn't exist</p>
            <Link style={{'display': 'block', 'textAlign': 'center', 'fontWeight': 'bold', 'fontSize': '24px', 'color': '#9F0013', 'marginTop': '30px'}} to="/">Back to main page</Link>
        </div>
    )
}

export default Page404;